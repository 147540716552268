<app-preloader *ngIf="isShowLoader" preloaderClass="preloader-section" class="preloader-section-component"></app-preloader>
<div mat-dialog-title>
  <div class="dialog-header">{{data?.title}}</div>
  <div class="denotes-info"><span class="astrik">*</span> denotes a required field</div>
  <mat-icon class="close-btn" [mat-dialog-close]>close</mat-icon>
</div>
<mat-dialog-content class="mat-typography">
  <mat-horizontal-stepper class="add-member-stepper" [disableRipple]="true" #addMemberStepper labelPosition="bottom"
    linear="true" [selectedIndex]="selectedStepIndex" (selectionChange)="stepChanged($event, addMemberStepper)">
    <ng-template matStepperIcon="edit">
      <mat-icon>done</mat-icon>
    </ng-template>
    <mat-step #boardInfoStep [stepControl]="addMemberForm">
      <ng-template matStepLabel>Member Information</ng-template>
      <form [formGroup]="addMemberForm" class="add-member">
        <!-- form section starts -->
        <div class="form-section">
          <p>
            The individual's email address will serve as the individual's NACD
            Username.
          </p>
          <!-- form row starts -->
          <div class="form-row">
            <div class="form-row-cell">
              <app-select label="Salutation" [items]="prefixData" valueKey="value" labelKey="displayText"
                [control]="addMemberForm.controls.prefix" [errorMsgs]=""></app-select>
            </div>
            <div class="form-row-cell">
              <app-address-autocomplete label="First Name" [formControl]="addMemberForm.controls.firstName"
                [errorMsgs]="validationMessages.firstName" [requireMark]="true" (emitAddress)="selectFirstName($event)"
                [suggestions]="firstNameSuggestions"></app-address-autocomplete>
            </div>
            <div class="form-row-cell">
              <app-input label="Middle Initial" [control]="addMemberForm.controls.mi"
                [errorMsgs]="validationMessages.mi"></app-input>
            </div>
            <div class="form-row-cell">
              <app-input label="Last Name" [control]="addMemberForm.controls.lastName"
                [errorMsgs]="validationMessages.lastName" [requireMark]="true"></app-input>
            </div>
          </div>
          <!-- form row ends -->

          <!-- form row starts -->
          <div class="form-row">
            <div class="form-row-cell">
              <app-select label="Suffix" [items]="suffixData" valueKey="value" labelKey="displayText"
                [control]="addMemberForm.controls.suffix" [errorMsgs]=""></app-select>
            </div>
            <div class="form-row-cell">
              <app-input label="Preferred Name" [control]="addMemberForm.controls.preferredName"
                [errorMsgs]="validationMessages.preferredName"></app-input>
            </div>
            <div class="form-row-cell">
              <app-input label="Email" [control]="addMemberForm.controls.email"
                [errorMsgs]="validationMessages.email" [requireMark]="true"></app-input>
            </div>
            <div class="form-row-cell">
              <app-input label="Phone" hint="(Please include country code)" [control]="addMemberForm.controls.phone"
                [errorMsgs]="validationMessages.phone" [requireMark]="false"></app-input>
            </div>
          </div>
          <!-- form row ends -->

          <div class="form-row">
            <div class="form-row-cell">
              <app-checkbox [requireMark]="true" [control]="addMemberForm.controls.isUSAddress"
                checkBoxLabel="Address Autocomplete (US only)" (emitChange)="changeUSAddress($event)"
                [inputData]="defaultUSAddress"></app-checkbox>
            </div>
          </div>
          <!-- form row starts -->
          <div class="form-row">
            <div class="form-row-cell">
              <app-address-autocomplete label="Address Line 1" [formControl]="addMemberForm.controls.addressLine1"
                [errorMsgs]="validationMessages.addressLine1" (emitAddress)="selectSuggestion($event)"
                [suggestions]="suggestions"></app-address-autocomplete>
            </div>
            <div class="form-row-cell">
              <app-input label="Address Line 2" [control]="addMemberForm.controls.addressLine2"
                [errorMsgs]="validationMessages.addressLine2"></app-input>
            </div>
            <div class="form-row-cell">
              <app-input label="City" [control]="addMemberForm.controls.city"
                [errorMsgs]="validationMessages.city"></app-input>
            </div>
            <div class="form-row-cell">
              <app-input label="State" [control]="addMemberForm.controls.state"
                [errorMsgs]="validationMessages.state"></app-input>
            </div>
          </div>
          <!-- form row ends -->

          <!-- form row starts -->
          <div class="form-row">
            <div class="form-row-cell">
              <app-input label="Country" [control]="addMemberForm.controls.country"
                [errorMsgs]="validationMessages.country"></app-input>
            </div>
            <div class="form-row-cell">
              <app-input label="Zip Code" [control]="addMemberForm.controls.zipcode"
                [errorMsgs]="validationMessages.zipcode" [requireMark]="true"></app-input>
            </div>
            <div class="form-row-cell">
              <div>
                <app-address-autocomplete label="Day Job Company" [formControl]="addMemberForm.controls.dayJob"
                  [errorMsgs]="validationMessages.dayJob" [requireMark]="false" [hint]="'Enter Day Job Company'"
                  (emitAddress)="selectCompany($event)" [suggestions]="companySuggestions" [isShowAdd]="
                  dayJobCompanyName != addMemberForm.controls.dayJob.value &&
                  addMemberForm.controls.dayJob.value != null &&
                  addMemberForm.controls.dayJob.value.length > 2 &&
                  companySuggestions.length == 0
                "></app-address-autocomplete>
              </div>
            </div>
            <div class="form-row-cell">
              <app-input label="Day Job Title" [control]="addMemberForm.controls.dayJobTitle"
                [errorMsgs]="validationMessages.dayJobTitle"></app-input>
            </div>
          </div>
        </div>
      </form>
    </mat-step>
    <!-- Second Step: Role Information -->
    <mat-step [stepControl]="roleInfoForm">
      <ng-template matStepLabel>Member Role</ng-template>
      <app-add-member-role-form [roleInfoForm]="roleInfoForm" [existingRoles]="this.profileToUpdate?.profileRoles || profileRoles"></app-add-member-role-form>
    </mat-step>
  </mat-horizontal-stepper>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <ng-container *ngIf="addMemberStepper?.selectedIndex === 0; else elseTemplate">
    <app-button class="mr-10" buttonClass="secondary-button" [buttonLabel]="'Cancel'"
      [mat-dialog-close]></app-button>
    <app-button buttonClass="primary-button" [buttonLabel]="'Next'" [disable]="addMemberForm.invalid"
      (onClick)="goNext(addMemberStepper)"></app-button>
  </ng-container>
  <ng-template #elseTemplate>
    <app-button class="mr-10" buttonClass="secondary-button" [buttonLabel]="'Back'"
      (onClick)="goBack(addMemberStepper)"></app-button>
    <app-button buttonClass="primary-button" [buttonLabel]="addButtonName" [disable]="roleInfoForm.invalid"
      (onClick)="saveData()"></app-button>
  </ng-template>
</mat-dialog-actions>