import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  EMPTY,
  catchError,
  debounceTime,
  distinctUntilChanged,
  of,
  startWith,
  switchMap,
} from 'rxjs';
import { AppConstants, TOKEN } from 'src/app/shared/constants/app-constants';
import { VALIDATION_MESSAGES_CONST } from 'src/app/shared/constants/common-constants';
import { Company } from 'src/app/shared/models/company';
import { MemberDto, MembershipDto } from 'src/app/shared/models/order-details';
import { Profile } from 'src/app/shared/models/profile';
import { Setting } from 'src/app/shared/models/setting';
import { AddressValidatorService } from 'src/app/shared/models/validator';
import { UtilityService } from 'src/app/shared/services/app-util.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AutocompleteService } from 'src/app/shared/services/autocomplete.service';
import { CompanyService } from 'src/app/shared/services/company.service';
import { EmailHelper } from 'src/app/shared/services/email-helper.service';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { MembershipService } from 'src/app/shared/services/membership.service';
import { ModalDataService } from 'src/app/shared/services/modal-data.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { GlobalDataProviderService } from 'src/app/shared/services/registration-data.service';
import { SettingService } from 'src/app/shared/services/setting.service';
import { AppSnackBarService } from 'src/app/shared/services/snackbar.service';

@Component({
  selector: 'app-edit-pbc',
  templateUrl: './edit-pbc.component.html',
  styleUrls: ['./edit-pbc.component.scss'],
})
export class EditPbcComponent implements OnInit {
  validationMessages = {
    firstName: VALIDATION_MESSAGES_CONST.firstName,
    lastName: VALIDATION_MESSAGES_CONST.lastName,
    preferredName: VALIDATION_MESSAGES_CONST.preferredName,
    mi: VALIDATION_MESSAGES_CONST.mi,
    phone: VALIDATION_MESSAGES_CONST.phone,
    email: VALIDATION_MESSAGES_CONST.email,
    addressLine1: VALIDATION_MESSAGES_CONST.addressLine1,
    addressLine2: VALIDATION_MESSAGES_CONST.addressLine2,
    city: VALIDATION_MESSAGES_CONST.city,
    state: VALIDATION_MESSAGES_CONST.state,
    country: VALIDATION_MESSAGES_CONST.country,
    zipcode: VALIDATION_MESSAGES_CONST.zipcode,
  };

  adminInfoForm = new FormGroup(
    {
      prefix: new FormControl(''),
      firstName: new FormControl('', [
        this.utilityService.noWhitespaceValidator,
        this.utilityService.minLengthValidator(1),
        this.utilityService.maxLengthValidator(40),
        Validators.pattern(AppConstants.NAME_REGEX),
        Validators.required,
      ]),
      mi: new FormControl('', [
        this.utilityService.minLengthValidator(1),
        this.utilityService.maxLengthValidator(2),
        Validators.pattern('^[a-zA-Z]*$'),
      ]),
      lastName: new FormControl('', [
        this.utilityService.noWhitespaceValidator,
        this.utilityService.minLengthValidator(2),
        this.utilityService.maxLengthValidator(80),
        Validators.pattern(AppConstants.NAME_REGEX),
        Validators.required,
      ]),
      suffix: new FormControl('', []),
      preferredName: new FormControl('', [
        this.utilityService.minLengthValidator(1),
        this.utilityService.maxLengthValidator(50),
        Validators.pattern(AppConstants.PREFERRED_NAME_REGEX),
      ]),
      phone: new FormControl('', [
        this.utilityService.minLengthValidator(7),
        Validators.pattern(AppConstants.PHONE_REGEX),
        Validators.required,
      ]),
      email: new FormControl('', [
        this.utilityService.noWhitespaceValidator,
        this.utilityService.minLengthValidator(5),
        this.utilityService.maxLengthValidator(100),
        Validators.pattern(AppConstants.EMAIL_REGEX),
        Validators.required,
      ]),
      isEnteredAHomeAddress: new FormControl(false),
      addressLine1: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(255),
        Validators.pattern(AppConstants.ADDRESS_LINE_REGEX)
      ]),
      addressLine2: new FormControl('', [
        this.utilityService.minLengthValidator(1),
        this.utilityService.maxLengthValidator(255),
        Validators.pattern(AppConstants.ADDRESS_LINE_REGEX)
      ]),
      city: new FormControl('', [
        this.utilityService.minLengthValidator(3),
        this.utilityService.maxLengthValidator(100),
        Validators.pattern(AppConstants.CITY_REGEX),
        Validators.required,
      ]),
      state: new FormControl('', [
        this.utilityService.noWhitespaceValidator,
        this.utilityService.minLengthValidator(2),
        this.utilityService.maxLengthValidator(100),
        Validators.pattern('^[a-zA-Z ]*$'),
        Validators.required,
      ]),
      country: new FormControl('', [
        this.utilityService.noWhitespaceValidator,
        this.utilityService.minLengthValidator(3),
        this.utilityService.maxLengthValidator(100),
        Validators.pattern('^[a-zA-Z ]*$'),
        Validators.required,
      ]),
      zipcode: new FormControl('', [
        this.utilityService.minLengthValidator(5),
        this.utilityService.maxLengthValidator(10),
        Validators.pattern(AppConstants.ZIPCODE_US_REGEX),
        Validators.required,
      ]),
      isUSAddress: new FormControl(true),
    },
    {}
  );

  public sysSettings: Setting[] = [];
  public commonSettings: Setting[] = [];
  suffixData: any;
  prefixData: any;
  companyData!: Company;
  profileData!: any;
  suggestions: any[] = [];
  defaultUSAddress = true;
  disableUSAddress = false;
  isAdmin = false;
  adminEmail!: string;
  initialFormValues: any;

  constructor(
    private companyService: CompanyService,
    private profileService: ProfileService,
    private globalData: GlobalDataProviderService,
    private registrationService: GlobalDataProviderService,
    private settingService: SettingService,
    private modalService: ModalDataService,
    private snackbarService: AppSnackBarService,
    private injector: Injector,
    private autocompleteService: AutocompleteService,
    private utilityService: UtilityService,
    private authService: AuthService,
    private emailHelperService: EmailHelper,
    private membershipService: MembershipService,
    private addressValidatorService: AddressValidatorService
  ) {}

  ngOnInit(): void {
    this.sysSettings = this.settingService.getSysSettingsData();
    this.prefixData = this.settingService.getSalutations();
    this.suffixData = this.getSysSetting(AppConstants.SYSSETTINGSUFFIX);
    this.companyData = this.companyService.activeCompany;
    const injectedData: any = this.injector.get(TOKEN);
    this.profileData = injectedData.contactData;
    this.isAdmin = !injectedData.isPbc;
    this.loadDataOnLoad(this.profileData);
    this.onAddressLineChange();
    this.initialFormValues = this.adminInfoForm.value;
  }

  public getSysSetting(key: string): Setting[] {
    this.commonSettings = this.sysSettings.filter((data) => data.key === key);
    return this.commonSettings;
  }

  loadDataOnLoad(adminData: Profile) {
    if (!adminData) return;
    this.adminInfoForm.patchValue(
      {
        prefix: adminData.prefix,
        firstName: adminData.firstName,
        mi: adminData.middleInitial,
        lastName: adminData.lastName,
        suffix: adminData.suffix,
        preferredName: adminData.preferredName,
        phone: adminData.phone,
        email: adminData.eMail,
        isEnteredAHomeAddress: adminData.address?.isHomeAddress,
        addressLine1: adminData.address?.line1,
        addressLine2: adminData.address?.line2,
        city: adminData.address?.city,
        state: adminData.address?.state,
        country: adminData.address?.country,
        zipcode: adminData.address?.zipCode,
        isUSAddress: true,
      },
      { emitEvent: false }
    );
    this.adminEmail = adminData.eMail!;
    if (!this.authService.isSuperAdminUser()) {
      FormControlService.disableFormControl(this.adminInfoForm.controls.email);
    }
    this.adminInfoForm.markAllAsTouched();
  }

  selectCompanyAddress(event: MatCheckboxChange) {
    if (event.checked) {
      this.defaultUSAddress = false;
      this.disableUSAddress = true;
      this.copyCompanyAddress();
    } else {
      this.disableUSAddress = false;
      FormControlService.enableFormControls([
        this.adminInfoForm.controls.addressLine1,
        this.adminInfoForm.controls.addressLine2,
        this.adminInfoForm.controls.city,
        this.adminInfoForm.controls.state,
        this.adminInfoForm.controls.country,
        this.adminInfoForm.controls.zipcode,
      ]);
      this.adminInfoForm.patchValue({
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        zipcode: '',
      });
    }
  }

  copyCompanyAddress() {
    this.adminInfoForm.patchValue({
      addressLine1: this.companyData?.address.line1,
      addressLine2: this.companyData?.address.line2,
      city: this.companyData?.address.city,
      state: this.companyData?.address.state,
      country: this.companyData?.address.country,
      zipcode: this.companyData?.address.zipCode,
    });
    FormControlService.disableFormControls([
      this.adminInfoForm.controls.addressLine1,
      this.adminInfoForm.controls.addressLine2,
      this.adminInfoForm.controls.city,
      this.adminInfoForm.controls.state,
      this.adminInfoForm.controls.country,
      this.adminInfoForm.controls.zipcode,
    ]);
  }

  hasFormValuesChanged(): boolean {
    return (
      JSON.stringify(this.initialFormValues) !==
      JSON.stringify(this.adminInfoForm.value)
    );
  }

  isNullOrEmpty(value: any): boolean {
    if (value == '' || value == null) {
      return true;
    }
    return false;
  }

  saveProfile() {
    if (!this.hasFormValuesChanged()) {
      this.modalService.hideLoader();
      this.modalService.closeModal({ close: true });
      return;
    }
    const formData = this.adminInfoForm.getRawValue();
    let profile: any = {
      userName: formData.email!.trim(),
      eMail: formData.email!.trim(),
      firstName: formData.firstName!.trim(),
      lastName: formData.lastName!.trim(),
      middleInitial: !this.isNullOrEmpty(formData.mi)
        ? formData.mi!.trim()
        : '',
      prefix: !this.isNullOrEmpty(formData.prefix)
        ? formData.prefix!.trim()
        : '',
      suffix: !this.isNullOrEmpty(formData.suffix)
        ? formData.suffix!.trim()
        : '',
      preferredName: !this.isNullOrEmpty(formData.preferredName)
        ? formData.preferredName!.trim()
        : '',
      phone: formData.phone!,
      isActive: true,
      address: {
        isHomeAddress: formData.isEnteredAHomeAddress === null ? false : formData.isEnteredAHomeAddress,
        line1: formData.addressLine1!.trim(),
        line2: !this.isNullOrEmpty(formData.addressLine2)
          ? formData.addressLine2!.trim()
          : '',
        city: formData.city!.trim(),
        state: formData.state!.trim(),
        country: formData.country!.trim(),
        zipCode: formData.zipcode!.trim(),
      },
    };

    this.modalService.showLoader();
    if (this.isAdmin) {
      this.updateAdmin(profile);
      return;
    }
    let previousEmail = this.registrationService
      .getMemberRosterProfiles()
      ?.find((profile) =>
        profile.companyInfo?.find(
          (c) => (c.id === this.companyData!.id && c.isPrimary) || c.isAdmin
        )
      )?.eMail;

    //  if pbc email id is not changed clear the email value
    if (this.profileData?.eMail === profile.eMail) {
      previousEmail = '';
    }

    this.modalService.showLoader();
    profile.companyId = this.companyData.id;
    delete profile.userName;
    if (profile.eMail !== this.adminEmail) {
      profile.previousPBCEmail = this.adminEmail;
    }

    if (
      this.profileData?.companyInfo?.length &&
      this.profileData.companyInfo.some(
        (ci: any) => ci.id === profile.companyId
      )
    ) {
      const role = this.profileData.companyInfo.find(
        (ci: any) => ci.id === profile.companyId
      );
      profile.role = role.role;
      profile.roleType = role.roleType;
    }
    console.log('form data', profile);
    let isActiveState =
      this.membershipService.activeMembership?.member?.find(
        (am) => am.eMail === profile.previousPBCEmail
      )?.isActive;
    this.profileService.updatePrimaryBoardContactProfile(profile).subscribe({
      next: (response) => {
        // updating the pbc
        let pbcData = response.data.filter((se: any) =>
          se.role.some((ro: any) => ro.id === 'Primary Board Contact')
        );
        this.profileService
          .getProfilesByCompany(this.companyData.id!)
          .subscribe({
            next: (data) => {
              data.data.forEach((da: any) => {
                da.isActive = true;
                da.isDeleted = false;
              });
              this.updateMembership(
                response.data,
                data.data,
                profile.previousPBCEmail,
                profile.email,
                isActiveState
              );
              this.modalService.closeModal({
                close: true,
                result: pbcData[0],
              });
            },
            error: (err) => console.log(`Failed to fetch membership ${err}`),
          });
      },
      error: (err) => {
        this.modalService.hideLoader();
        this.snackbarService.showErrorMessage(err.error.message, 'OK');
      },
    });
  }

  memberShip: MembershipDto = <MembershipDto>{};
  updateMembership(
    profiles: Profile[],
    rosters: any,
    previousEmail: string,
    email: string,
    isActiveState: any
  ) {
    let members: any = [];
    if (profiles?.length) {
      profiles.forEach((se: any) => {
        const role = se.companyInfo?.find(
          (ci: any) => ci.id === this.companyData?.id
        );
        const member = {} as MemberDto;
        (member.id = se.id!),
          (member.isDeleted = false),
          (member.isAddForRenewal = false),
          (member.isPrimary = se?.companyInfo?.find(
            (c: any) => c.id === this.companyData?.id
          )?.isPrimary!),
          (member.eMail = se.eMail!),
          (member.isActive = member.isPrimary);

        // expired account update is active as false
        if (
          this.membershipService.activeMembership?.endDate &&
          new Date() >
            new Date(this.membershipService.activeMembership?.endDate) && member.isPrimary
        ) {
          member.isActive = isActiveState;
        }
        members.push(member);
      });

      this.memberShip =
        this.membershipService.activeMembership ??
        this.registrationService.getMembershipDto();
      this.membershipService
        .updateMembershipRoster(
          this.memberShip.id!,
          members.filter((me: any) => me.isPrimary)
        )
        .subscribe({
          next: (data: any) => {
            this.modalService.hideLoader();
            if (members.some((me: any) => !me.isActive && !me.isPrimary)) {
              this.modalService.showLoader();
              this.inactiveMember(
                members.filter((me: any) => !me.isActive && !me.isPrimary)[0],
                rosters
              );
            }

            if (previousEmail !== email) {
              this.emailHelperService.emailLogNewBpc();
            }
          },
          error: (err: any) => {
            console.log(`Failed to update membership ${err}`);
            this.modalService.hideLoader();
          },
        });
    }
  }

  // while changing pbc email id inactive the previous email Id member
  inactiveMember(member: any, rosters: any) {
    const activeMembersCount: number = this.memberShip.member.filter(
      (m) => m.isActive
    ).length;
    const memberCount =
      this.memberShip.count < activeMembersCount
        ? this.memberShip.count + 1
        : this.memberShip.count;
    this.membershipService
      .updateGracePeriod(this.memberShip.id!, member, memberCount)
      .subscribe({
        next: (data: any) => {
          this.modalService.hideLoader();
          this.membershipService.setActiveMembership(data.data);
          this.globalData.setMemberRosterProfiles(rosters);
          this.globalData.setMemberRosterDataObservable();
        },
        error: (error) => {
          this.modalService.hideLoader();
          console.log(`Failed to update membership ${error}`);
        },
      });
  }

  updateAdmin(profile: any) {
    profile.companyId = this.companyData.id;
    delete profile.userName;
    if (profile.eMail !== this.adminEmail) {
      profile.previousAdminEmail = this.adminEmail;
    }
    this.profileService.updateAdmin(profile).subscribe({
      next: (response) => {
        this.modalService.hideLoader();
        let adminData = response.data.filter((se: any) =>
          se.role.some((ro: any) => ro.id === 'Administrator')
        );
        // updating the admin
        this.profileService
          .getProfilesByCompany(this.companyData.id!)
          .subscribe({
            next: (data) => {
              this.globalData.setMemberRosterProfiles(data.data);
              this.modalService.closeModal({
                close: true,
                result: adminData[0],
              });
              if (this.adminEmail !== profile.eMail) {
                this.emailHelperService.emailLogNewBpc();
              }
            },
            error: (err) => console.log(`Failed to fetch membership ${err}`),
          });
      },
      error: (err) => {
        this.modalService.hideLoader();
        if (err.error?.message) {
          this.snackbarService.showErrorMessage(err.error.message, 'OK');
        }
      },
    });
  }

  selectSuggestion(value: any): void {
    if (value) {
      const line1 = `${value.number ? value.number : ''}  ${
        value.street ? value.street : ''
      }`;
      this.adminInfoForm.patchValue(
        {
          addressLine1: value.addressLabel ? value.addressLabel : line1.trim(),
          city: value.city,
          state: value.state,
          zipcode: value.postalCode,
          country: value.country,
          addressLine2: '',
        },
        { emitEvent: false }
      );
      // updating the formControl state
      [
        'addressLine1',
        'addressLine2',
        'city',
        'state',
        'country',
        'zipcode',
      ].forEach((se) => {
        const field = this.adminInfoForm.get(se);
        field?.markAsDirty();
        field?.markAsTouched();
      });
    }
  }

  changeUSAddress(event: MatCheckboxChange) {

    this.defaultUSAddress = event.checked;
    if (!this.defaultUSAddress) {
      this.adminInfoForm.patchValue(
        {
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          country: '',
          zipcode: '',
        },
        { emitEvent: false }
      );
    } else {
      this.adminInfoForm.patchValue(
        {
          isUSAddress: true,
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          country: '',
          zipcode: '',
        },
        { emitEvent: false }
      );
    }

    this.addressValidatorService.setAddressLine1Validators(
      this.adminInfoForm.controls['addressLine1'],
      this.defaultUSAddress
    );

    [
      'addressLine1',
      'addressLine2',
      'city',
      'state',
      'country',
      'zipcode',
    ].forEach((se) => {
      const field = this.adminInfoForm.get(se);
      field?.markAsDirty();
      field?.markAsTouched();
    });

    this.addressValidatorService.setZipCodeValidators(
      this.adminInfoForm.controls['zipcode'],
      this.defaultUSAddress,
      this.validationMessages
    );
  }

  setAddressAsHome(event: MatCheckboxChange){
    this.adminInfoForm.controls.isEnteredAHomeAddress.setValue(event.checked);
  }

  getHomeAddressToolTip(){
    return AppConstants.HOME_BUSINESS_ADDRESS_TOOLTIP;
  }

  onAddressLineChange() {
    this.adminInfoForm.controls.addressLine1.valueChanges
      .pipe(
        startWith(''),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((filterValue) => {
          const data =
            typeof filterValue === 'string' ? filterValue.trim() : '';

          if (!!data && this.defaultUSAddress) {
            return this.autocompleteService
              .getAutocompleteSuggestions(data)
              .pipe(
                catchError((error) => {
                  console.log(error);
                  return EMPTY;
                })
              );
          } else {
            return of({ data: [] });
          }
        })
      )
      .subscribe({
        next: (response) => {
          const output =
            response.addresses || response.places || response.pois || [];
          this.suggestions = [...output];
        },
        error: (err) => {
          console.log(err);
          this.suggestions = [];
        },
      });
  }
}
