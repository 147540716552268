import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MembershipStatus } from '../constants/app-constants';
import { BaseResponse } from '../models/base-response';
import { MembershipPricing } from '../models/membership-pricing';
import { MemberDto, MembershipDto } from '../models/order-details';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class MembershipService extends BaseService {
  activeMembership!: MembershipDto | undefined;
  latestMembership!: MembershipDto | undefined;
  membersCount = 0;
  memberShipTerm = '';
  companyName = '';

  activeMembershipObs$ = new BehaviorSubject<any>({});

  getActiveMembershipObs(): Observable<MembershipDto> {
    return this.activeMembershipObs$.asObservable();
  }

  setActiveMembership(membership: MembershipDto) {
    this.activeMembership = membership;
    this.activeMembershipObs$.next(membership);
  }

  clearActiveMembership() {
    this.activeMembershipObs$.next(this.activeMembership);
  }

  getMembershipStandardPricing(): Observable<
    BaseResponse<MembershipPricing[]>
  > {
    return this._http.get<BaseResponse<MembershipPricing[]>>(
      `${this.baseURL}/MembershipPrice`
    );
  }

  get isRenewPeriodActive(): boolean {
    const isInRenewPeriod =
      this.daysPendingToExpiry < 90 &&
      this.activeMembership?.status == MembershipStatus.COMPLETE;

    if (isInRenewPeriod && this.latestMembership) {
      if (this.activeMembership?.id == this.latestMembership?.id) {
        return true;
      } else {
        if (this.latestMembership.status == MembershipStatus.COMPLETE) {
          return false;
        }
      }
    }

    return false;
  }

  get daysPendingToExpiry(): number {
    const currentDate = new Date();
    const membershipEndDate = new Date(this.activeMembership?.endDate!);
    const diff = membershipEndDate.getTime() - currentDate.getTime();
    return Math.ceil(diff / (1000 * 3600 * 24));
  }

  getMembershipByCompany(
    companyId: string,
    isRestrictLatestMembership = false
  ): Observable<BaseResponse<MembershipDto>> {
    if (!isRestrictLatestMembership) {
      this.getLatestMembershipByCompany(companyId).subscribe((response) => {
        this.latestMembership = response.data;
      });
    }
    const url = `${this.baseURL}/Membership/Company/${companyId}`;
    return this._http.get<BaseResponse<MembershipDto>>(url);
  }

  getLatestMembershipByCompany(
    companyId: string
  ): Observable<BaseResponse<MembershipDto>> {
    const url = `${this.baseURL}/Membership/LatestMembership/${companyId}`;
    return this._http.get<BaseResponse<MembershipDto>>(url);
  }

  getCompanyIdWithSubscription(companyId: string): Observable<string> {
    const url = `${this.baseURL}/Membership/CompanyIdWithSubscription/${companyId}`;
    return this._http.get<string>(url);
  }
  updateMembership(membership: MembershipDto): Observable<any> {
    const url = `${this.baseURL}/Membership`;
    return this._http.put<any>(url, membership);
  }

  updateMembershipRoster(
    membershipId: string,
    member: MemberDto[],
    memberCount?: number,
    restrictRosterSyncAPI = false
  ): Observable<any> {
    let url = `${this.baseURL}/Membership/Roster?membershipId=${membershipId}`;
    if (memberCount) url = url + `&memberCount=${memberCount}`;
    // if(tempMemberId) {
    //   url = url + `&profileTempId=${tempMemberId}`
    // }
    url = url + `&restrictRosterSyncAPI=${restrictRosterSyncAPI}`;
    return this._http.put<any>(url, member);
  }

  updateGracePeriod(
    membershipId: string,
    member: MemberDto,
    memberCount?: number,
    restrictRosterSyncAPI = false
  ): Observable<any> {
    const payload = [member];
    let url = `${this.baseURL}/Membership/DeleteOrInActiveMember?membershipId=${membershipId}`;
    if (memberCount) url = url + `&memberCount=${memberCount}`;
    url = url + `&restrictRosterSyncAPI=${restrictRosterSyncAPI}`;
    return this._http.put<any>(url, payload);
  }

  getMembershipCount(companyId: string) {
    const url = `${this.baseURL}/Membership/MembershipCount/${companyId}`;
    return this._http.get<any>(url);
  }
  getProfileByFirstnameLastnameEmailCompanyId(
    firstName: string,
    profileId: string,
    email: string,
    boardCompanyId: string
  ) {
    console.log('in membership srvie' + profileId);
    return this._http.get<any>(
      `${this.baseURL}/Membership/FirstNameAcrossRoster/${firstName}/${email}/${boardCompanyId}/${profileId}/${boardCompanyId}`
    );
  }

  getDuplicateContacts(
    pageSize: number,
    direction: string,
    contactName?: string,
    sortColumn?: string,
    sortDirection?: string
  ) {
    if (contactName) {
      contactName = encodeURIComponent(contactName);
    }
    let url = `${this.baseURL}/Membership/DuplicateContacts?direction=${direction}&pageSize=${pageSize}`;
    if (contactName) url = url + '&contactName=' + contactName;
    if (sortColumn) url = url + '&sortColumn=' + sortColumn;
    if (sortDirection) url = url + '&sortDirection=' + sortDirection;
    return this._http.get<any>(url);
  }

  //service for getting member roster for super admin module - it is done thru service
  private memberRoster = new BehaviorSubject<any>(null);
  member$ = this.memberRoster.asObservable();

  memberRosterAction(actionHandle: any) {
    this.memberRoster.next(actionHandle);
  }

  getchildDuplicateContacsList(
    parent_id: string,
    pageSize: number,
    direction: string,
    sortingCoumnName: string,
    sortingDirection: string,
    filterValue: string
  ): Observable<BaseResponse<any[]>> {
    // getchildDuplicateContacsList(parent_id:string,pageSize:number,direction:string,sortingCoumnName:string,sortingDirection:string,filterValue:string): Observable<BaseResponse<any[]>> {
    // console.log(`${this.baseURL}/DuplicateCompanies?id=${parent_id}&direction=${direction}&pageSize=${pageSize}&sortingCoumnName=${sortingCoumnName}&sortingDirection=${sortingDirection}&companyName=${filterValue}`);
    if (filterValue) {
      filterValue = encodeURIComponent(filterValue);
    }
    return this._http.get<BaseResponse<any[]>>(
      `${this.baseURL}/Membership/DuplicateContactsByInlineTable?id=${parent_id}&direction=${direction}&pageSize=${pageSize}&sortingCoumnName=${sortingCoumnName}&sortingDirection=${sortingDirection}&companyName=${filterValue}`
    );
  }
}
