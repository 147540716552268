import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatStep, MatStepper } from '@angular/material/stepper';
import * as _ from 'lodash';
import {
  EMPTY,
  catchError,
  debounceTime,
  distinctUntilChanged,
  of,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { ModalWrapperComponent } from 'src/app/shared/common-components/modal-wrapper/modal-wrapper.component';
import { AppConstants, TOKEN } from 'src/app/shared/constants/app-constants';
import { VALIDATION_MESSAGES_CONST } from 'src/app/shared/constants/common-constants';
import { Company } from 'src/app/shared/models/company';
import { ProfileRole } from 'src/app/shared/models/membership';
import { MemberDto, MembershipDto } from 'src/app/shared/models/order-details';
import { Profile } from 'src/app/shared/models/profile';
import { Setting } from 'src/app/shared/models/setting';
import { UtilityService } from 'src/app/shared/services/app-util.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AutocompleteService } from 'src/app/shared/services/autocomplete.service';
import { CompanyService } from 'src/app/shared/services/company.service';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { MembershipService } from 'src/app/shared/services/membership.service';
import { ProfileRoleService } from 'src/app/shared/services/profile-role.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { GlobalDataProviderService } from 'src/app/shared/services/registration-data.service';
import { SettingService } from 'src/app/shared/services/setting.service';
import { AppSnackBarService } from 'src/app/shared/services/snackbar.service';
import { BoardInformationComponent } from '../board-information/board-information.component';
import { FirstnameConfirmboxComponent } from '../firstname-confirmbox/firstname-confirmbox.component';
import { MemberRosterActivateComponent } from '../member-roster-activate/member-roster-activate.component';
import { AddressValidatorService } from 'src/app/shared/models/validator';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss'],
})
export class AddMemberComponent implements OnInit {
  addButtonName = 'Add';
  successMsg: string = AppConstants.MEMBER_ADD_MSG;
  errorMsg: string = AppConstants.MEMBER_ADD_ERROR_MSG;
  memberExistMsg: string = AppConstants.MEMBER_ALREADY_EXIST_MSG;
  public sysSettings: Setting[] = [];
  public commonSettings: Setting[] = [];
  @Input() matStep: MatStep | undefined;
  @Input() stepperObj: MatStepper | undefined;
  suffixData: any;
  prefixData: any;
  companyData: Company | undefined;
  profileToUpdate: any;
  existingData: any;
  formSubmitted = false;
  memberShip!: MembershipDto;
  injectedDate: any;
  forRenewal = false;
  companySuggestions = [];
  firstNameSuggestions = [];
  dayJobCompanyId = '';
  dayJobCompanyName = '';
  companyId = '';
  selectedMember: any;
  suggestions: any[] = [];
  validationMessages = VALIDATION_MESSAGES_CONST;
  dayJobTemp = '';
  roleInfoForm: FormGroup = new FormGroup({
    memberRoles: new FormArray([]),
  });

  addMemberForm = new FormGroup({
    prefix: new FormControl(''),
    firstName: new FormControl('', [
      Validators.required,
      this.utilityService.noWhitespaceValidator,
      this.utilityService.minLengthValidator(1),
      this.utilityService.maxLengthValidator(40),
      Validators.pattern(AppConstants.NAME_REGEX),
    ]),
    mi: new FormControl('', [
      this.utilityService.minLengthValidator(1),
      this.utilityService.maxLengthValidator(2),
      Validators.pattern('^[a-zA-Z]*$'),
    ]),
    lastName: new FormControl('', [
      this.utilityService.noWhitespaceValidator,
      this.utilityService.minLengthValidator(2),
      this.utilityService.maxLengthValidator(80),
      Validators.pattern(AppConstants.NAME_REGEX),
      Validators.required,
    ]),
    suffix: new FormControl(''),
    preferredName: new FormControl('', [
      this.utilityService.minLengthValidator(1),
      this.utilityService.maxLengthValidator(50),
      Validators.pattern(AppConstants.PREFERRED_NAME_REGEX),
    ]),
    phone: new FormControl('', [
      this.utilityService.minLengthValidator(7),
      Validators.pattern(AppConstants.PHONE_REGEX),
    ]),
    email: new FormControl('', [
      this.utilityService.minLengthValidator(5),
      this.utilityService.maxLengthValidator(100),
      Validators.pattern(AppConstants.EMAIL_REGEX),
      Validators.required,
    ]),
    roleTypes: new FormControl(''),
    role: new FormControl(''),
    isEnteredAHomeAddress: new FormControl(false),
    addressLine1: new FormControl('', [
      this.utilityService.minLengthValidator(5),
      this.utilityService.maxLengthValidator(255),
      Validators.pattern(AppConstants.ADDRESS_LINE_REGEX)
    ]),
    addressLine2: new FormControl('', [
      this.utilityService.minLengthValidator(1),
      this.utilityService.maxLengthValidator(255),
      Validators.pattern(AppConstants.ADDRESS_LINE_REGEX)
    ]),
    city: new FormControl('', [
      this.utilityService.minLengthValidator(3),
      this.utilityService.maxLengthValidator(100),
      Validators.pattern(AppConstants.CITY_REGEX),
    ]),
    state: new FormControl('', [
      this.utilityService.minLengthValidator(2),
      this.utilityService.maxLengthValidator(100),
      Validators.pattern('^[a-zA-Z ]*$'),
    ]),
    country: new FormControl('', [
      this.utilityService.minLengthValidator(3),
      this.utilityService.maxLengthValidator(100),
      Validators.pattern('^[a-zA-Z ]*$'),
    ]),
    zipcode: new FormControl('', [
      this.utilityService.minLengthValidator(5),
      this.utilityService.maxLengthValidator(10),
      Validators.pattern(AppConstants.ZIPCODE_US_REGEX),
      Validators.required,
    ]),
    dayJob: new FormControl('', [
      Validators.minLength(3),
      Validators.maxLength(255),
    ]),
    dayJobTitle: new FormControl('', [
      this.utilityService.minLengthValidator(2),
      this.utilityService.maxLengthValidator(128),
    ]),
    isUSAddress: new FormControl(true),
  });

  selectedStepIndex = 0;
  isShowLoader = false;
  profileRoles = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private registrationService: GlobalDataProviderService,
    private settingService: SettingService,
    private profileService: ProfileService,
    private snackbarService: AppSnackBarService,
    private injector: Injector,
    private membershipService: MembershipService,
    private companyService: CompanyService,
    private authService: AuthService,
    private modal: MatDialog,
    private utilityService: UtilityService,
    private autoCompleteService: AutocompleteService,
    private profileRoleService: ProfileRoleService,
    private addressValidatorService: AddressValidatorService
  ) { }

  ngOnInit(): void {
    this.isShowLoader = true;
    this.injectedDate = this.data;
    this.addButtonName = !this.injectedDate?.isEdit ? 'Add' : 'Update';

    if (this.injectedDate) {
      this.profileToUpdate = this.injectedDate.profileInfo;
      this.forRenewal = this.injectedDate.isRenewal;
    }
    this.onFirstNameChange();
    this.onCompanyNameChange();
    this.onDayJobCompanyChange();
    this.onAddressLineChange();
    this.updateAddMemberForm();
    // this.roleYears = getRoleYears();
    this.companyData =
      this.companyService.activeCompany ??
      this.registrationService.getCompanyData();
    this.sysSettings = this.settingService.getSysSettingsData();
    this.prefixData = this.settingService.getSalutations();
    this.suffixData = this.getSysSetting(AppConstants.SYSSETTINGSUFFIX);
    this.memberShip =
      this.membershipService.activeMembership ??
      this.registrationService.getMembershipDto();
  }

  stepChanged(event: any, stepper: any) {
    // load updated info
    if (event.selectedIndex === 1) {
    }

    stepper.selected.interacted = false;
  }

  public getSysSetting(key: string): Setting[] {
    this.commonSettings = this.sysSettings.filter((data) => data.key === key);
    return this.commonSettings;
  }

  getCompanyFromProfile(data: any) {
    return data.companyInfo.find(
      (value: any) => value.id === this.companyData?.id
    );
  }

  getMemberRosterRoleFromSettings(role: string): string {
    const roleSetting = this.getSysSetting(
      AppConstants.SYSSETTING_MEMBERROSTER_ROLE
    ).find((s) => s.displayText === role);
    return roleSetting?.displayText ? roleSetting.displayText : role;
  }

  getDayJobCompany(company: string) {
    this.isShowLoader = true;
    this.companyService.getCompanyById(company).subscribe({
      next: (data) => {
        if (data && data.data) {
          this.isShowLoader = false;
          this.addMemberForm.patchValue(
            {
              dayJob: data.data.name,
            },
            { emitEvent: false }
          );
          this.dayJobCompanyName = data.data.name;
          this.existingData = this.addMemberForm.getRawValue();
        }
      },
    });
  }

  updateAddMemberForm() {
    if (this.profileToUpdate) {
      this.successMsg = AppConstants.MEMBER_UPDATE_MSG;
      this.errorMsg = AppConstants.MEMBER_UPDATE_ERROR_MSG;
      this.addButtonName = 'Update';
      this.addMemberForm.patchValue(
        {
          prefix: this.profileToUpdate.prefix,
          firstName: this.profileToUpdate.firstName,
          mi: this.profileToUpdate.middleInitial,
          lastName: this.profileToUpdate.lastName,
          suffix: this.profileToUpdate.suffix,
          preferredName: this.profileToUpdate.preferredName,
          phone: this.profileToUpdate.phone,
          email: this.profileToUpdate.eMail,
          isEnteredAHomeAddress: this.profileToUpdate.address?.isHomeAddress,
          addressLine1: this.profileToUpdate.address?.line1,
          addressLine2: this.profileToUpdate.address?.line2,
          city: this.profileToUpdate.address?.city,
          state: this.profileToUpdate.address?.state,
          country: this.profileToUpdate.address?.country,
          zipcode: this.profileToUpdate.address?.zipCode,
          dayJobTitle: this.profileToUpdate.dayJobTitle,
        },
        { emitEvent: false }
      );
      if (
        this.addMemberForm.controls.email.value &&
        this.memberShip &&
        !this.authService.isSuperAdminUser()
      )
        FormControlService.disableFormControl(
          this.addMemberForm.controls.email
        );
      if (this.profileToUpdate.dayJobCompany) {
        this.dayJobCompanyId = this.profileToUpdate.dayJobCompany;
        this.getDayJobCompany(this.profileToUpdate.dayJobCompany);
      }

      this.addMemberForm.markAllAsTouched();
    }
    this.existingData = this.addMemberForm.getRawValue();
    this.isShowLoader = false;
  }

  isUserPBC() {
    if (this.profileToUpdate) {
      return !!this.profileToUpdate?.companyInfo.some((c: any) => c.id === this.companyData?.id && c.isPrimary);
    } else {
      return false;
    }
  }

  validateMemberExists(): boolean {
    const email = this.addMemberForm.controls.email.value?.toLowerCase();
    if (this.profileToUpdate && email) return false;
    const profileArr = this.registrationService.getMemberRosterProfiles();
    if (profileArr && profileArr.length > 0 && !this.memberShip) {
      if (email) {
        if (
          profileArr.filter((profile) => profile.eMail?.toLowerCase() === email)
            .length > 1 // this to avoid duplicate admin email entry
        ) {
          return true;
        }
        if (
          profileArr.some(
            (se) =>
              se.companyInfo?.some(
                (vs) => vs.isAdmin && se.eMail?.toLowerCase() === email
              ) &&
              !se.companyInfo?.some(
                (vs) => vs.isPrimary && se.eMail?.toLowerCase() === email
              )
          )
        ) {
          return false;
        }
        // if email is there, check for profile with same email
        const prfl = profileArr.find(
          (profile) => profile.eMail?.toLowerCase() === email
        );
        // const prf2 = profileArr.find((profile) => profile.role![0].id === "Administrator");
        //if(profile.role![0].id === "Administrator")
        if (
          (prfl && !prfl.member) ||
          (prfl && prfl.member && prfl.member.isDeleted === false)
        ) {
          return true;
        }
      }
    }

    if (
      this.memberShip?.member?.some(
        (se) => se.eMail?.toLowerCase() === email && se.isDeleted === false
      )
    ) {
      return true;
    }
    return false;
  }

  saveData() {
    if (!this.validateMemberExists()) {
      this.addMember();
    } else {
      this.snackbarService.showErrorMessage(this.memberExistMsg, 'OK');
    }
  }

  checkDayJobCompanyUserPBC(dayJobCompany: string) {
    if (
      this.injectedDate.ismatStep &&
      this.isUserPBC() &&
      dayJobCompany !== this.companyData!.id
    ) {
      this.isShowLoader = false;

      this.snackbarService.showErrorMessage(
        'Day Job Company cannot be updated for Primary Board Contact',
        'OK'
      );

      if (this.companyData && this.companyData!.id) {
        this.getDayJobCompany(this.companyData.id);
        this.dayJobCompanyId = this.companyData.id;
      }
      return true;
    }
    return false;
  }

  mapProfileData() {
    const formData = this.addMemberForm.getRawValue();
    const profileRoles: ProfileRole[] = this.roleInfoForm.get('memberRoles')?.getRawValue();
    profileRoles.forEach(r => r.isActive = !((this.profileToUpdate?.member && !this.profileToUpdate?.member?.isActive) || r?.endYear !== 'Current'));

    const profileInfo = {
      userName: formData.email ? formData.email.trim() : '',
      eMail: formData.email ? formData.email.trim() : '',
      firstName: formData.firstName ? formData.firstName.trim() : '',
      lastName: formData.lastName ? formData.lastName.trim() : '',
      middleInitial: formData.mi ? formData.mi.trim() : '',
      prefix: formData.prefix ? formData.prefix : '',
      suffix: formData.suffix ? formData.suffix : '',
      preferredName: formData.preferredName!,
      phone: formData.phone ? formData.phone : '',
      isBoardAdvisor: false,
      isDeleted: false,
      isActive: true,
      dayJobCompany:
        formData.dayJob && this.dayJobCompanyId ? this.dayJobCompanyId : '',
      dayJobTitle: formData.dayJobTitle ?? '',
      dayJobCompanyName:
        formData.dayJob && this.dayJobCompanyId ? formData.dayJob : '',
      address: {
        isHomeAddress: formData.isEnteredAHomeAddress === null ? false : formData.isEnteredAHomeAddress,
        line1: formData.addressLine1 ? formData.addressLine1.trim() : '',
        line2: formData.addressLine2 ? formData.addressLine2.trim() : '',
        city: formData.city ? formData.city.trim() : '',
        state: formData.state ? formData.state.trim() : '',
        country: formData.country ? formData.country.trim() : '',
        zipCode: formData.zipcode ? formData.zipcode.trim() : '',
      },
      companyInfo: [
        {
          id: this.companyData?.id,
          isPrimary: this.isUserPBC(),
          isAdmin: false, // in add member we are not updating and adding admin
        },
      ],
      role: [
        {
          id: this.isUserPBC()
            ? this.getMemberRosterRoleFromSettings('Primary Board Contact')
            : this.getMemberRosterRoleFromSettings('Member'),
        },
      ],
      profileRoles: profileRoles,
    };

    return profileInfo;
  }

  addMember() {
    this.isShowLoader = true;
    const formData = this.addMemberForm.getRawValue();

    if (this.isNullOrEmpty(formData.email) && this.isUserPBC()) {
      this.isShowLoader = false;
      this.snackbarService.showErrorMessage(
        'Primary Board Contact Email cannot be empty',
        'OK'
      );

      this.modal.closeAll();
      return;
    }

    var dayJobCompanyEdited =
      formData.dayJob && this.dayJobCompanyId ? this.dayJobCompanyId : '';

    //  initial registration restrict the day job company edit
    var isErrorShown = this.checkDayJobCompanyUserPBC(dayJobCompanyEdited);
    if (isErrorShown) {
      return;
    }

    const profile: Profile = this.mapProfileData();

    // in company creation cannot add admin as member
    if (this.injectedDate.ismatStep) {
      const profiles: Profile[] =
        this.registrationService.getMemberRosterProfiles();
      if (profiles?.length) {
        const admin = profiles.find((p) =>
          p.companyInfo?.find(
            (c) => c.id === this.companyData!.id && c.isAdmin && !c.isPrimary
          )
        );
        if (admin?.eMail === profile.eMail) {
          this.isShowLoader = false;
          this.snackbarService.showErrorMessage(
            'Administrator can be added to the roster only after registration is completed.',
            'OK'
          );
          return;
        }
      }
    }

    let payload = this.profileToUpdate
      ? { ...this.profileToUpdate, ...profile }
      : profile;
    if (this.isAdminAsMember(formData.email!)) {
      const adminData = this.getAdminDetails();
      payload.address = _.mergeWith(
        adminData.address,
        payload.address,
        (a, b) => {
          return b === '' ? a : b;
        }
      );
      if (payload.phone === '' || payload.phone === undefined)
        payload.phone = adminData.phone;
    }

    let previousEmail = this.registrationService
      .getMemberRosterProfiles()
      ?.find((profile) =>
        profile.companyInfo?.find(
          (c) => (c.id === this.companyData!.id && c.isPrimary) || c.isAdmin
        )
      )?.eMail;

    // clearing the email id if role is member only;
    if (
      payload.role?.length &&
      payload.role.every((ro: any) => ro.id === 'Member')
    ) {
      previousEmail =
        this.profileToUpdate?.eMail === payload.eMail
          ? ''
          : this.profileToUpdate?.eMail;
    }

    // if pbc email id is not changed clear the email value
    if (this.profileToUpdate?.eMail === payload.eMail && this.isUserPBC()) {
      previousEmail = '';
    }

    // As per #5618 skip role validation for inactive member
    const isMemberActiveInRoster = !!this.profileToUpdate?.member?.isActive;

    this.profileService.saveProfile(payload, previousEmail, isMemberActiveInRoster).subscribe({
      next: (response) => {
        if (!this.memberShip) {
          // this block of code executes when add a member in registration step
          this.isShowLoader = false;
          this.snackbarService.showSuccessMessage(this.successMsg, 'OK');
          if (
            !this.memberShip &&
            payload.id &&
            payload.id !== response.data[0].id
          ) {
            const index = this.registrationService
              .getMemberRosterProfiles()
              .findIndex((p) => p.id === payload.id);
            if (index != -1)
              this.registrationService
                .getMemberRosterProfiles()
                .splice(index, 1);
          }
          // update data
          if (response.data)
            this.registrationService.setMemberRosterProfile(response.data[0]);

          if (this.isUserPBC()) {
            this.registrationService.setPrimaryContact(response.data[0]);
            this.registrationService.isSavedPrimaryBoardInfo = true;
          }

          this.modal.closeAll();
        } else {
          // this block of code executes when add a member after membership created
          this.updateMembership(payload, response.data[0]);
        }
      },
      error: (err) => {
        this.isShowLoader = false;
        if (err.error?.message) {
          this.snackbarService.showErrorMessage(err.error.message, 'OK');
        }
      },
    });
  }

  updateMembership(oldProfile: Profile, updatedProfile: Profile) {
    if (updatedProfile) {
      const isPrimary: boolean = updatedProfile.companyInfo?.find(
        (c) => c.id === this.companyData?.id
      )?.isPrimary!;
      let member: MemberDto = {
        id: oldProfile.id ? oldProfile.id : updatedProfile.id!,
        //isActive: !this.profileToUpdate && this.forRenewal ? false : true,
        //As per #3947 isActive is true for all cases
        //As per #5618 isActive update based on membership (oldProfile?.member?.isActive)
        isActive: !!oldProfile?.member ? !!oldProfile?.member?.isActive : true,
        isDeleted: false,
        // isAddForRenewal:
        //   (!this.profileToUpdate && this.forRenewal) ||
        //   (this.profileToUpdate && this.forRenewal && oldProfile.id)
        //     ? true
        //     : false,
        // As per #3947 isAddForRenewal is false for all cases
        isAddForRenewal: false,
        isPrimary: isPrimary,
        eMail: this.addMemberForm.controls.email.value!,
      };
      //whenever isAddForRenewal is true, then isActive should be true.
      //As per #3947 isActive is true for all cases
      //member.isActive = member.isAddForRenewal ? true : member.isActive;
      const members = this.memberShip.member;
      const index = members.findIndex((m) => m.id === member.id);
      if (index >= 0) {
        //check if old profile and update profile id is not same. if not same then make old one as deleted
        //and update membership members with update profile id.
        //NOTE: old profile id and update profile id case for email address optional
        if (oldProfile.id && oldProfile.id !== updatedProfile.id) {
          oldProfile.member = { ...member };
          oldProfile.member.isDeleted = true;
          member.id = updatedProfile.id!;
          member.profileTempId = oldProfile.id;
        }
        this.memberShip.member[index] = member;
      } else {
        this.memberShip.member.push(member);
      }

      //no need to update membership object for renewal update member
      // As per #3947 we need to update membership all the time on renewal flow
      // if (this.profileToUpdate && this.forRenewal && !oldProfile.id) {
      //   updatedProfile.member = member;
      //   this.registrationService.setMemberRosterProfile(updatedProfile);
      //   this.snackbarService.showSuccessMessage(this.successMsg, 'OK');
      //   this.modalService.closeModal({ close: true });
      //   return;
      // }
      //update membercount for renewal flow #3497
      let activeMembersCount: number = this.memberShip.member.filter(
        (m) => m.isActive
      ).length;
      let memberCount: any = !this.forRenewal
        ? undefined
        : this.memberShip.count < activeMembersCount
          ? this.memberShip.count + 1
          : this.memberShip.count;

      // for expired account restricting roster sync api in BE
      let restrictRosterSyncAPI = false;
      if (
        this.forRenewal &&
        this.memberShip &&
        new Date() > new Date(this.memberShip.endDate!)
      ) {
        restrictRosterSyncAPI = true;
      }

      this.membershipService
        .updateMembershipRoster(
          this.memberShip.id!,
          [member],
          memberCount,
          restrictRosterSyncAPI
        )
        .subscribe({
          next: (data) => {
            this.isShowLoader = false;
            // if (this.forRenewal) {
            //   this.membershipService.setActiveMembership(this.memberShip);
            // } else {
            //   this.membershipService.setActiveMembership(data.data);
            // }
            // As per #3947 always set active membership
            this.membershipService.setActiveMembership(data.data);
            if (oldProfile.id && oldProfile.id !== updatedProfile.id) {
              this.registrationService.setMemberRosterProfile(oldProfile);
            }
            this.registrationService.setMemberRosterProfile(updatedProfile);
            this.snackbarService.showSuccessMessage(this.successMsg, 'OK');
            this.modal.closeAll();
          },
          error: (err) => {
            console.log(`Failed to update membership ${err}`);
            this.isShowLoader = false;
          },
        });
    }
  }

  onCompanyNameChange() {
    this.addMemberForm.controls.dayJob.valueChanges
      .pipe(
        startWith(''),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((filterValue) => {
          const data =
            typeof filterValue === 'string' ? filterValue.trim() : '';

          if (data?.length > 2) {
            return this.companyService.searchCompanyForDayJobCompany(data).pipe(
              tap((response) => {
                this.dayJobTemp = '';
                this.dayJobTemp = data;
                return response;
              }),
              catchError((error) => {
                console.log(error);
                return EMPTY;
              })
            );
          } else {
            return of({ data: [] });
          }
        })
      )
      .subscribe({
        next: (response) => {
          if (response.data?.length) {
            const companySuggestionsTemp: never[] = response.data.map(
              (d: any) => {
                d.formattedAddress = d.name;
                return d;
              }
            );
            this.companySuggestions = [...companySuggestionsTemp];
          } else {
            this.companySuggestions = [];
          }
        },
        error: (err) => {
          console.log(err);
          this.companySuggestions = [];
        },
      });
  }

  private onDayJobCompanyChange() {
    // When a new day job company is created, update the dayJobCompanyName to prevent the display of the '+ New Company' button.
    this.companyService.dayJobCompany$.subscribe((data) => {
      if (data) {
        this.dayJobCompanyName = data.name;
      }
    });
  }

  onFirstNameChange(): void {
    this.addMemberForm.controls.firstName.valueChanges
      .pipe(
        startWith(''),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((filterValue) => {
          const data =
            typeof filterValue === 'string' ? filterValue.trim() : '';
          if (
            data?.length > 2 &&
            !this.injectedDate.isEdit &&
            (!!this.memberShip ||
              (!this.memberShip && this.authService.isSuperAdminUser()))
          ) {
            this.companyId = this.companyData?.id as string;
            const companyIdTemp = !this.authService.isSuperAdminUser()
              ? this.companyId
              : undefined;
            return this.profileService
              .getProfilesByFirstName(encodeURIComponent(data), companyIdTemp)
              .pipe(
                catchError((error) => {
                  console.log(error);
                  return EMPTY;
                })
              );
          } else {
            return of({ data: [] });
          }
        })
      )
      .subscribe({
        next: (response) => {
          const firstNameValue = this.addMemberForm.controls?.firstName?.value;
          const data =
            typeof firstNameValue === 'string' ? firstNameValue.trim() : '';
          this.firstNameSuggestions =
            !!data && data.length > 2
              ? response.data.map((d: any) => ({
                ...d,
                formattedAddress: `${d.firstName} \n${d.lastName} ${d.eMail}`,
              }))
              : [];
        },
        error: (err) => {
          console.log(err);
          this.firstNameSuggestions = [];
        },
      });
  }

  selectCompany(value: any) {
    if (value && value.name && value.id) {
      try {
        this.addMemberForm.patchValue(
          {
            dayJob: value.name,
          },
          { emitEvent: false }
        );
        this.dayJobCompanyId = value.id;
        var isErrorShown = this.checkDayJobCompanyUserPBC(value.id);
        if (!isErrorShown) {
          this.moalConfig('existing Day Job Company.');
          this.dayJobCompanyName = value.name;
        }
        return;
      } catch (error) { }
    } else {
      var isErrorShown = this.checkDayJobCompanyUserPBC(value.id);
      if (!isErrorShown) {
        this.createBoard();
      }
    }
  }

  selectFirstName(value: any) {
    if (value && value.firstName && value.id) {
      this.selectedMember = value;
      if (
        this.memberShip?.member?.some(
          (m) => m.id === this.selectedMember?.id && !m.isActive
        )
      ) {
        this.addMemberForm.controls.firstName.setValue(value.firstName);
        this.activate(this.selectedMember);
        return;
      }

      if (
        this.memberShip?.member?.some(
          (m) => m.id === this.selectedMember?.id && m.isDeleted == false
        )
      ) {
        this.snackbarService.showErrorMessage(
          'Selected user is already an active member in this account. ',
          'OK'
        );
      }

      this.profileRoleService.getByProfileId(value.id).subscribe({
        next: (data) => {
          this.fillAddMemberForm(value, data.data);
        },
        error: (err) => {
          console.log(err);
          this.firstNameSuggestions = [];
        },
      });
    }
  }

  isNullOrEmpty(value: any): boolean {
    if (value == '' || value == null) {
      return true;
    }
    return false;
  }

  fillAddMemberForm(value: any, profileRoles?: any) {
    this.addMemberForm.patchValue(
      {
        prefix: !this.isNullOrEmpty(value.prefix) ? value.prefix : '',
        firstName: value.firstName,
        mi: !this.isNullOrEmpty(value.middleInitial) ? value.middleInitial : '',
        lastName: value.lastName,
        email: value.eMail,
        suffix: !this.isNullOrEmpty(value.suffix) ? value.suffix : '',
        addressLine1: value.address.line1,
        addressLine2: value.address.line2,
        city: value.address.city,
        state: value.address.state,
        country: value.address.country,
        zipcode: value.address.zipCode,
        phone: value.phone,
        preferredName: value.preferredName,
      },
      { emitEvent: false }
    );

    if (value?.profileRoles?.length || profileRoles?.length) {
      const profileRolesData = value.profileRoles || profileRoles;
      profileRolesData.forEach((pr: any) => {
        pr.id = '';
        pr.profileId = '';
        pr.userId = '';
      });
      this.profileRoles = profileRolesData.filter(
        (pr: any) => pr.companyId === this.companyId
      );
    }

    this.addMemberForm.markAllAsTouched();
  }

  moalConfig(title: string) {
    const modalConfig = {
      enterAnimationDuration: '200ms',
      exitAnimationDuration: '200ms',
      data: {
        component: FirstnameConfirmboxComponent,
        title: 'Proceed with ' + title,
        showCloseIcon: true,
      },
      panelClass: 'app-dialog',
      disableClose: true,
    };
    const modalRef = this.modal.open(FirstnameConfirmboxComponent, modalConfig);
    modalRef.afterClosed().subscribe((item) => {
      if (item == 'CLOSE_ADD_MEMBER_MODAL') {
        // this.modal.closeAll();
      } else {
        this.addMemberForm.controls.dayJob.setValue('');
        this.dayJobCompanyName = '';
      }
    });
  }

  isAdminAsMember(email: string): boolean {
    const profiles: Profile[] =
      this.registrationService.getMemberRosterProfiles();
    if (profiles) {
      const admin = profiles.find((p) =>
        p.companyInfo?.find(
          (c) =>
            c.id === this.companyData!.id &&
            c.isAdmin === true &&
            c.isPrimary === false
        )
      );
      if (admin && admin.eMail === email) {
        return true;
      }
    }
    return false;
  }

  getAdminDetails(): Profile {
    const profiles: Profile[] =
      this.registrationService.getMemberRosterProfiles();
    const admin = profiles.find((p) =>
      p.companyInfo?.find(
        (c) =>
          c.id === this.companyData!.id &&
          c.isAdmin === true &&
          c.isPrimary === false
      )
    );
    return admin!;
  }

  createBoard() {
    this.membershipService.companyName = this.dayJobTemp || 'isAdd';
    const modalConfig = {
      enterAnimationDuration: '200ms',
      exitAnimationDuration: '200ms',
      data: {
        component: BoardInformationComponent,
        title: 'Create Day Job Company',
        showCloseIcon: true,
      },
      panelClass: ['app-dialog', 'account-edit-modal'],
      disableClose: true,
    };
    const modalRef = this.modal.open(ModalWrapperComponent, modalConfig);
    modalRef.afterClosed().subscribe((item) => {
      this.dayJobTemp = '';
      if (!this.membershipService.companyName) {
        this.dayJobCompanyId = this.companyService.dayJobCompany.id!;
        this.addMemberForm.controls.dayJob.setValue(
          this.companyService.dayJobCompany.name,
          { emitEvent: false }
        );
      } else {
        this.addMemberForm.controls.dayJob.setValue('');
        this.dayJobCompanyId = '';
        this.companySuggestions = [];
        this.membershipService.companyName = '';
      }
    });
  }

  selectSuggestion(value: any): void {
    if (value) {
      const line1 = `${value.number ? value.number : ''}  ${value.street ? value.street : ''
        }`;
      this.addMemberForm.patchValue(
        {
          addressLine1: value.addressLabel ? value.addressLabel : line1.trim(),
          city: value.city,
          state: value.state,
          zipcode: value.postalCode,
          country: value.country,
          addressLine2: '',
        },
        { emitEvent: false }
      );
      // updating the formControl state
      [
        'addressLine1',
        'addressLine2',
        'city',
        'state',
        'country',
        'zipcode',
      ].forEach((se) => {
        const field = this.addMemberForm.get(se);
        field?.markAsDirty();
        field?.markAsTouched();
      });
    }
  }

  onAddressLineChange() {
    this.addMemberForm.controls.addressLine1.valueChanges
      .pipe(
        startWith(''),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((filterValue) => {
          const data =
            typeof filterValue === 'string' ? filterValue.trim() : '';

          if (!!data && this.defaultUSAddress) {
            return this.autoCompleteService
              .getAutocompleteSuggestions(data)
              .pipe(
                catchError((error) => {
                  console.log(error);
                  return EMPTY;
                })
              );
          } else {
            return of({ data: [] });
          }
        })
      )
      .subscribe({
        next: (response) => {
          const output =
            response.addresses || response.places || response.pois || [];
          this.suggestions = [...output];
        },
        error: (err) => {
          console.log(err);
          this.suggestions = [];
        },
      });
  }

  defaultUSAddress = true;
  changeUSAddress(event: MatCheckboxChange) {
    this.defaultUSAddress = event.checked;
    if (!this.defaultUSAddress) {
      this.addMemberForm.patchValue(
        {
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          country: '',
          zipcode: '',
        },
        { emitEvent: false }
      );
      this.suggestions = [];
    } else {
      this.addMemberForm.patchValue({
        isUSAddress: true,
        addressLine1: '',
      });
    }

    this.addressValidatorService.setAddressLine1Validators(
      this.addMemberForm.controls['addressLine1'],
      this.defaultUSAddress,
      false
    );

    this.addressValidatorService.setZipCodeValidators(
      this.addMemberForm.controls['zipcode'],
      this.defaultUSAddress,
      this.validationMessages
    );
  }

  setAddressAsHomeForMember(event: MatCheckboxChange){
    this.addMemberForm.controls.isEnteredAHomeAddress.setValue(event.checked);
  }

  getHomeAddressToolTip(){
    return AppConstants.HOME_BUSINESS_ADDRESS_TOOLTIP;
  }

  activate(row: any) {
    let input: any = {};
    let profile: any;
    if (row?.id) {
      profile = this.getProfileById(row.id);
      input.profile = profile;
      input.isActive = true;
      if (!this.forRenewal)
        input.membersCount = this.injectedDate.remainingMembers;
      input.forRenewal = this.forRenewal;
    }

    this.injector = Injector.create({
      providers: [{ provide: TOKEN, useValue: input }],
      parent: this.injector,
    });

    const modalConfig = {
      enterAnimationDuration: '200ms',
      exitAnimationDuration: '200ms',
      data: {
        component: MemberRosterActivateComponent,
        injector: this.injector,
      },
      panelClass: 'app-dialog',
      disableClose: true,
    };
    const modalRef = this.modal.open(ModalWrapperComponent, modalConfig);
    modalRef.afterClosed().subscribe((item) => {
      this.selectedMember = {};
      this.addMemberForm.controls.firstName.setValue('');
    });
  }

  getProfileById(id: string) {
    return this.injectedDate.profileArr.find(
      (profile: any) => profile.id === id
    );
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goNext(stepper: MatStepper) {
    stepper.next();
  }
}
