import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class RestAPIInterceptor implements HttpInterceptor {
  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      httpRequest.url.indexOf('connect/') > -1 ||
      httpRequest.url.indexOf('api.radar.io/') > -1 ||
      httpRequest.url.indexOf('authorize.net/') > -1
    ) {
      return next.handle(httpRequest);
    }
    const accessToken =
      httpRequest.url.indexOf('EmailLogChange?code=') > -1
        ? localStorage.getItem('azure_function_token')
        : localStorage.getItem('API_TOKEN');
    //any alteration in httpRequest can be done here like adding auth token
    const req = httpRequest.clone({
      setHeaders: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    });
    return next.handle(req);
  }
}
