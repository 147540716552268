import { AppConstants } from "./app-constants";

export const VALIDATION_MESSAGES_CONST = {
    firstName: {
        required: 'First Name is required',
        minlength: 'First Name should be atleast 1 character',
        maxlength: 'First Name cannot be more than 40 characters',
        pattern: "Only letters, accent characters, spaces, hyphens, or apostrophes are allowed.",
    },
    lastName: {
        required: 'Last Name is required',
        minlength: 'Last Name should be atleast 2 characters',
        maxlength: 'Last Name cannot be more than 80 characters',
        pattern: "Only letters, accent characters, spaces, hyphens, or apostrophes are allowed.",
    },
    mi: {
        minlength: 'MI should be atleast 1 character',
        maxlength: 'MI cannot be more than 2 characters',
        pattern: 'Special characters and numbers are not allowed',
    },
    preferredName: {
        minlength: 'Preferred Name should be atleast 1 character',
        maxlength: 'Preferred Name cannot be more than 50 characters',
        pattern: "Only letters, spaces, hyphens, or apostrophes are allowed.",
    },
    phone: {
        required: 'Phone is required',
        minlength: 'Your phone number is too short',
        pattern: "Only numbers, spaces, and the special characters '+', '(', ')', and '-' are allowed.",
    },
    email: {
        required: 'Email is required',
        minlength: 'Email should be atleast 5 characters',
        maxlength: 'Email cannot be more than 100 characters',
        pattern: 'Invalid email address pattern',
    },
    roleTypes: {
        required: 'A Role Type is required',
    },
    role: {
        required: 'A Role is required',
    },
    addressLine1: {
        required: 'Address Line 1 is required',
        minlength: 'Address Line 1 should be atleast 5 characters',
        maxlength: 'Address Line 1 cannot be more than 255 characters',
        pattern: "Only letters, numbers, spaces, commas, periods, hyphens, and apostrophes are allowed."
    },
    addressLine2: {
        minlength: 'Address Line 2 should be atleast 1 characters',
        maxlength: 'Address Line 2 cannot be more than 255 characters',
        pattern: "Only letters, numbers, spaces, commas, periods, hyphens, and apostrophes are allowed."
    },
    city: {
        required: 'City is required',
        minlength: 'City should be atleast 3 characters',
        maxlength: 'City cannot be more than 100 characters',
        pattern: "Only letters, spaces, periods, hyphens (-), and apostrophes (') are allowed.",
    },
    state: {
        required: 'State is required',
        minlength: 'State should be atleast 2 characters',
        maxlength: 'State cannot be more than 100 characters',
        pattern: 'Special characters and numbers are not allowed',
    },
    country: {
        required: 'Country is required',
        minlength: 'Country should be atleast 3 characters',
        maxlength: 'Country cannot be more than 100 characters',
        pattern: 'Special characters and numbers are not allowed',
    },
    zipcode: {
        required: 'Zipcode is required',
        minlength: 'Zipcode should be atleast 5 characters',
        maxlength: 'Zipcode cannot be more than 10 characters',
        pattern: AppConstants.ZIPCODE_US_ERROR_MESSAGE,
    },
    dayJob: {
        minlength: 'Day Job Company should be atleast 3 characters',
        maxlength: 'Day Job Company cannot be more than 255 characters',
    },
    dayJobTitle: {
        minlength: 'Day Job Title should be atleast 2 characters',
        maxlength: 'Day Job Title cannot be more than 128 characters',
    },
    startYear: {
        required: 'Role Start Year is required',
        max: 'Start year cannot be greater than the End year',
    },
    endYear: {
        required: 'Role End Year is required',
        min: 'End year cannot occur before the Start year',
    },
};